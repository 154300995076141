<template>
  <div class="shop-all-container">
    <div class="max-xl:px-7 xl:px-36 w-full">
      <div
        class="shop-all-header-shop max-w-rifyo mx-auto px-0 w-full flex flex-col"
      >
        <h1 class="shop-all-text w-full font-bold text-5xl mb-5">
          Sale
        </h1>
        <div
          class="w-full flex max-lg:flex-col max-lg:gap-5 lg:flex-row align-center justify-between"
        >
          <div class="shop-all-shop-title">
            <span class="shop-all-text1 m-0"
              >{{
                productTotal > 0
                  ? `${productTotal} items found`
                  : '0 item found'
              }}
            </span>
          </div>
           <div class="shop-all-filter-row lg:grid grid-cols-2 gap-4">
            <input-filter
              rootClassName="col-span-1 rounded-none"
              :on-option="selectCategory.bind(this)"
              :option-data="categoriesName"
            />
            <input-filter
              rootClassName="col-span-1 rounded-none"
              textinputPlaceholder="Sort By"
              :on-option="orderByProduct.bind(this)"
              :option-data="productParams.orderData"
            />
          </div>
        </div>
      </div>
      <div
        v-if="isLoading"
        class="loading flex justify-center items-center w-full"
      >
        <Loading />
      </div>
      <div v-else class="max-w-rifyo mx-auto">
        <div
          class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-16"
        >
          <div
            v-for="(product, index) in products"
            :key="'product-' + index"
            class="flex flex-grow-0"
          >
            <shop-card-sales
              :image_src="showImage(product)"
              :title="product.title"
              :hargaDisc="product.price"
              :hargaNormal="product.price_after_discount"
              :item="product"
            />
          </div>
        </div>
      </div>
      <div
        class="shop-all-container-pagination mb-16 max-w-rifyo mx-auto px-0 w-full"
        v-if="!isLoading"
      >
        <app-pagination
          v-if="productTotal > 0"
          :get-page="fecthProduct.bind(this)"
          rootClassName="rootClassName"
          :selected="productParams.page"
          :total="productTotal"
          :per_page="productParams.perPage"
          :current_page="productParams.currPage"
        ></app-pagination>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import InputFilter from '../components/input-filter';
  import ShopCardSales from '../components/shop-card-sales';
  import AppPagination from '../components/pagination';
  import Footer from '../components/footer';
  import Api from '../api';
  import Loading from '../components/Loading.vue';

  export default {
    name: 'Sales',
    components: {
      InputFilter,
      ShopCardSales,
      AppPagination,
      Footer,
      Loading,
    },
    data() {
      return {
        products: [],
        productTotal: 0,
        productParams: {
          page: 1,
          perPage: 12,
          currPage: 1,
          orderBy: 'latest',
          categoryId: null,
          orderData: ['latest', 'oldest', 'price_min', 'price_max'],
        },
        categories: [],
        categoriesName: [],
        category_id: null,
        isLoading: false,
      };
    },
    computed: {
      slug() {
        return this.$route.params.slug;
      },
    },
    created() {
      this.getProduct();
      this.getCategory();
    },
    methods: {
      showImage(item) {
        let text = item?.image
        const myArray = text.split(",");
        return item?.image
          ? myArray[0]
          : 'https://www.pngkey.com/png/detail/233-2332677_image-500580-placeholder-transparent.png';
      },
      fecthProduct(page) {
        this.productParams.page = page;
        this.getProduct();
      },
      orderByProduct(order) {
        this.productParams.orderBy = order;
        this.getProduct();
      },
      selectCategory(op) {
        if (op === 'ALL') {
          this.productParams.categoryId = null;
          this.getProductAll();
        } else {
          const item = this.categories.find((res) => res.title === op);
          this.productParams.categoryId = item.id;
          this.getProduct();
        }
      },
      async getProductAll() {
        try {
          this.isLoading = true;
          let fullUrl =
            Api.productSales +
            `&perpage=${
              this.productParams.perPage ? this.productParams.perPage : '10'
            }&page=${
              this.productParams.page
            }&order=${this.productParams.orderBy.toLowerCase()}`;
          const response = await this.$http.get(fullUrl);
          this.products = response.data.data;
          this.productTotal = response.data.total;
          this.productParams.perPage = response.data.per_page;
        } catch (error) {
          throw new Error(error);
        } finally {
          this.isLoading = false;
        }
      },
      async getProduct() {
        try {
          this.isLoading = true;
          let fullUrl =
            Api.productSales +
            `&perpage=${
              this.productParams.perPage ? this.productParams.perPage : '10'
            }&page=${
              this.productParams.page
            }&order=${this.productParams.orderBy.toLowerCase()}`;
          if (this.productParams.categoryId) {
            fullUrl = fullUrl + `&category_id=${this.productParams.categoryId}`;
          }
          if (this.$route.query.search) {
            fullUrl = fullUrl + `&search=${this.$route.query.search}`;
          }
          const response = await this.$http.get(fullUrl);
          this.products = response.data.data;
          this.productTotal = response.data.total;
          this.productParams.perPage = response.data.per_page;
        } catch (error) {
          throw new Error(error);
        } finally {
          this.isLoading = false;
        }
      },
      async getCategory() {
        try {
          const response = await this.$http.get(Api.categoryUrl);
          let subcategori = [];
          response.data.data.forEach((a) => {
            a.child.forEach((b) => {
              subcategori = [...subcategori, b];
            });
          });
          this.categories = subcategori;
          this.categoriesName = subcategori.map((item) => item.title);
          this.categoriesName = ['ALL', ...this.categoriesName];
        } catch (error) {
          throw new Error(error);
        }
      },
    },
    watch: {
      slug() {
        this.getProduct();
      },
    },
  };
</script>

<style scoped>
  .loading {
    margin-top: 164px;
    margin-bottom: 164px;
  }
  .shop-all-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .shop-all-header-shop {
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding-top: 160px;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
    justify-content: space-between;
  }
  .shop-all-shop-title {
    width: 240px;
    display: flex;
    flex-direction: column;
  }
  .shop-all-text {
    color: #1f252c;
    font-size: 2em;
    font-family: Lora;
  }
  .shop-all-text1 {
    color: #575f6e;
    font-size: 18px;
    margin-top: var(--dl-space-space-oneandhalfunits);
    font-family: Montserrat;
  }
  .shop-all-filter-row {
    flex: 0 0 auto;
    height: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
  }
  .shop-all-shop-list {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 56px;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
  }
  @media (max-width: 1024px) {
    .shop-all-shop-list {
      gap: 16px;
    }
  }
  .shop-all-container-pagination {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: center;
    justify-content: center;
  }
  /* .shop-card {
  width: 23%;
} */
  @media (max-width: 991px) {
    .shop-all-header-shop {
      flex-wrap: wrap;
      padding-top: 96px;
      padding-left: 16px;
      padding-right: 16px;
    }
    .shop-all-shop-title {
      width: 100%;
    }
    .shop-all-filter-row {
      width: 100%;
      margin-top: var(--dl-space-space-unit);
      justify-content: space-between;
    }
    .shop-all-shop-list {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 16px;
      padding-left: 16px;
      padding-right: 16px;
    }
    .shop-card {
      width: 100%;
    }
  }
</style>
