<template>
  <div class="shop-card" :class="rootClassName">
    <img :src="image_src" :alt="image_alt" class="shop-card-image" />
    <span class="shop-card-text">{{ title }}</span>
    <div class="shop-card-info">
      <span v-if="!simpleCard" class="shop-card-info-text">Start From</span>
      <div class="flex-grow flex">
        <div class="flex-grow">
          <del class="shop-card-text2">
            {{ $formatIDR(hargaDisc) }}
          </del>
          <p class="shop-card-text1">
            {{ $formatIDR(hargaNormal) }}
          </p>
        </div>
        <img class="w-6 self-center" :src="image_src1" :alt="image_alt1" />
      </div>
    </div>
    <router-link
      class="after:absolute after:inset-0"
      :to="`/product-detail/${item.parent_slug}`"
      ><span class="sr-only">Detail</span></router-link
    >
  </div>
</template>

<script>
  export default {
    name: 'ShopCard',
    props: {
      image_src: {
        type: String,
        default: '/playground_assets/chair2x.png',
      },
      image_alt: {
        type: String,
        default: 'image',
      },
      title: {
        type: String,
        default: 'Eames Lounge Chairs & Ottoman',
      },
      hargaNormal: {
        type: [String, Number],
        default: '0',
      },
      hargaDisc: {
        type: [String, Number],
        default: '0',
      },
      image_src1: {
        type: String,
        default: '/playground_assets/arrow-right-black.svg',
      },
      image_alt1: {
        type: String,
        default: 'image',
      },
      rootClassName: String,
      item: Object,
    },
    methods: {
      goPage(page) {
        if (this.item) {
          this.$router.push(`/${page}/${this.item.parent_slug}`);
          this.$store.commit('setProduct', { ...this.item });
          this.$emit('redirect');
        }
      },
    },
  };
</script>

<style scoped lang="postcss">
  .shop-card {
    @apply w-full flex relative flex-col cursor-pointer bg-white pb-4 mb-2.5;
  }
  .shop-card-image {
    @apply w-60 h-60 object-contain mx-auto;
  }
  .shop-card-text {
    @apply text-[#1f252c] text-lg font-lora font-bold flex-grow mb-2;
  }
  .shop-card-info {
    @apply w-full flex flex-col;
    @screen md {
      @apply flex-row;
    }
    &-text {
      @apply w-full mr-4 text-[#aea79d] font-sans;
      @screen md {
        @apply w-10;
      }
    }
  }
  .shop-card-info-nonsales {
    @apply w-full flex;
    &-text {
      @apply w-full mr-4 text-[#aea79d] font-sans;
    }
  }
  .shop-card-text1 {
    @apply font-sans text-lg text-[#1f252c];
    @screen lg {
      @apply mb-0;
    }
  }
  .shop-card-text2 {
    @apply font-sans text-sm line-through text-[#1f252c] opacity-40 mb-2;
  }

  .shop-card-image1 {
    @apply w-6 object-cover;
  }

  @media (max-width: 991px) {
    .shop-card {
      @apply h-auto;
    }
    .shop-card-text {
      @apply w-full;
    }
  }
  @media (max-width: 767px) {
    .shop-card-text {
      @apply text-base;
    }
    .shop-card-text1 {
      @apply text-sm;
    }
  }
</style>
